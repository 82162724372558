import { Button, TextField } from "@material-ui/core";
import React, { useContext, useState } from "react";
import SendIcon from "@material-ui/icons/Send";
import { useMutation } from "react-query";
import LoadingSpinner from "../../../../components/loading-spinner";
import { inviteUserToScenario } from "../../../../api/scenario";
import {
  useCompanyTheme,
  useQueryInvalidationWithNotification,
} from "../../../../hooks";
import { FinanceContext } from "../../../../context/finance-context";
import { checkEmailVerification } from "../../../../utils/email-verification";

const LoanApplicantInvite = ({ email = "" }) => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const { setSelectedScenarioId, selectedScenarioId } = useContext(
    FinanceContext
  );
  const theme = useCompanyTheme();

  const _inviteUserToScenario = useMutation(
    (payload) => inviteUserToScenario(payload),
    {
      onSuccess: (result) => {
        invalidateQueries("getConnectionByScenario");
        setSelectedScenarioId(result.scenarioId);
        invalidateQueries("scenarioList");
        invalidateQueries(["scenarioProductList", result.scenarioId]);
        invalidateQueries(["maxLoanHistoryChartData", result.scenarioId]);
        invalidateQueries([
          "maxBuyingPowerHistoryChartData",
          result.scenarioId,
        ]);
        invalidateQueries(["buyingPowerHistoryByScenario", result.scenarioId]);
      },
    }
  );

  const [value, setValue] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleChangeValue = (e) => {
    setValue(e.target.value);
    setIsValidEmail(!checkEmailVerification(e.target.value));
  };

  const isAbleToInvite = isValidEmail && value;

  return (
    <div className="applicant-container" style={{ height: 115 }}>
      <div className="flex flex-col">
        <p>Create Joint Application</p>
        <div>
          <TextField
            variant="outlined"
            size="small"
            id="outlined-size-small"
            className="scenario-invite-input__email"
            onChange={handleChangeValue}
            value={value}
            placeholder="Email"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            error={!isValidEmail}
            helperText={!isValidEmail ? "Invalid email" : ""}
          >
            {email}
          </TextField>
          <Button
            disabled={_inviteUserToScenario.isLoading || !isAbleToInvite}
            onClick={() => {
              _inviteUserToScenario.mutate({
                scenarioId: selectedScenarioId,
                emails: [value],
              });
            }}
            variant="contained"
            style={{
              height: 40,
              boxShadow: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              maxWidth: "40px !important",
              minWidth: 40,
              padding: 0,
              background: isAbleToInvite ? theme.primaryColor : "gray",
            }}
          >
            {_inviteUserToScenario.isLoading ? (
              <LoadingSpinner />
            ) : (
              <SendIcon
                color="white"
                style={{ color: "white", fontSize: 14 }}
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoanApplicantInvite;
